<template>
    <div class="roleTab clearfix">
      <global-table ref="libraryData" row-key="materialCode" :tableField="tableField" :tableData="tableData"
    ></global-table>
    </div>
</template>

<script>
// import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { tableField, tableData } from './OperationBody.js'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'OperationBody',
  props: ['dataBox'],
  data () {
    return {
      tableField: tableField,
      tableData: tableData
    }
  },
  created () {
    this.myBidData()
  },
  mounted () {

  },
  methods: {
    // 获取操作记录数据列表
    myBidData () {
      const id = this.dataBox.id
      request('/api/evaluation/sysLog/query?id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.tableData = res.data
        }
      })
    }
  },
  watch: {
  }
}
</script>
<style scoped>
  .tableNav{
    border: none;
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
</style>
